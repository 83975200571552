
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexyYKpdLTdIy72NbQOhja9KgCwSWs_S9jhfFtqJbmsT_45sMeta } from "/var/www/admin-next/pages/index.vue?macro=true";
import { default as logine_2WWutHG1k8VjQw__jBpD_T3UNrUq4vddq19kCh6B4Meta } from "/var/www/admin-next/pages/login.vue?macro=true";
import { default as tagsG4lBf5Ltsb3rmKLYVVj5Kb68BlV_NsKRgUXRzRDSqm0Meta } from "/var/www/admin-next/pages/merge/tags.vue?macro=true";
import { default as indexmxnJS10hLdiueFAT8GvYjKKbxN4QlxBdiPX32fqYWYkMeta } from "/var/www/admin-next/pages/merge/index.vue?macro=true";
import { default as usersEi0qFjpSQxOmoa2NpG7XnmaRaFkbqLQGron6YkWY1R0Meta } from "/var/www/admin-next/pages/merge/users.vue?macro=true";
import { default as companiescbJlo9GgT_453FU1g_45geKxuhs_ZVG36bhDTUXPWo9_qG4Meta } from "/var/www/admin-next/pages/merge/companies.vue?macro=true";
import { default as institutions7b1kBE_45RgMKk6vrsx8nGuf5CW_JPSu6ApXbs0ZWnb8gMeta } from "/var/www/admin-next/pages/merge/institutions.vue?macro=true";
import { default as merge2zXjvhycCD_457JqncaGpMbzGmHgPpbtAF8ywnvBOUt_458Meta } from "/var/www/admin-next/pages/merge.vue?macro=true";
import { default as amnesiaUZsb4bLgULuEHf6ZjaokOEyFFbGM22_4r43KFWPq54cMeta } from "/var/www/admin-next/pages/amnesia.vue?macro=true";
import { default as profileqIlgS_45z_Jln_tfsHrC6FsMODq116gUiHaRtLFV768q8Meta } from "/var/www/admin-next/pages/profile.vue?macro=true";
import { default as outreachZWkv4_45g6SD12SZZhJWD_thYoyJXrEJ_45l7iGGnvG7rhwMeta } from "/var/www/admin-next/pages/outreach.vue?macro=true";
import { default as indexzHrdjMzFbltbm8i3dov_45xCDCApalu246ddlqpq2zykAMeta } from "/var/www/admin-next/pages/settings/index.vue?macro=true";
import { default as labelssYo2b9cBauoZh48dblKdPl0lqYJHPtWMfMylzVGPYU8Meta } from "/var/www/admin-next/pages/settings/labels.vue?macro=true";
import { default as licensesZiT1hw5zdyc0tMq_XiacQGKNkveVSJueDCX9kDJYmuUMeta } from "/var/www/admin-next/pages/settings/licenses.vue?macro=true";
import { default as deal_45typesetEzXJN0VIipB1dYBFhYaZssrJlxfJxuhgKnghdBYYgMeta } from "/var/www/admin-next/pages/settings/deal-types.vue?macro=true";
import { default as industriesBMSf39oX9c1CdJDUhC37CKvHDknNxFjPZkBdhXFKMIgMeta } from "/var/www/admin-next/pages/settings/industries.vue?macro=true";
import { default as task_45typesy5WtU_8Hlwe_45qW6v_IUl1rDUfwJUl6nnmoieWIk0WI4Meta } from "/var/www/admin-next/pages/settings/task-types.vue?macro=true";
import { default as user_45typeslfeGLdTG61Fmc2ywllIe7iBo95EECnWB6y2Nil8QaeIMeta } from "/var/www/admin-next/pages/settings/user-types.vue?macro=true";
import { default as deal_45stagesJbzctLK9mp5lDxb03z3Qdg1BgC24TB8FhQSMeDmB3F0Meta } from "/var/www/admin-next/pages/settings/deal-stages.vue?macro=true";
import { default as event_45typesa66zelGhrJh_45O3XYYcArkuPotcR2DZjZN78R1J7qPmcMeta } from "/var/www/admin-next/pages/settings/event-types.vue?macro=true";
import { default as account_45typesrtQS94TL14Fxlx3FPzSI8iiNln8Wvd1afjB6tMzS6rIMeta } from "/var/www/admin-next/pages/settings/account-types.vue?macro=true";
import { default as company_45typessdsDtcfNGSoKWos2GkPqut8NT3nu3cxLgHva0ixEZOYMeta } from "/var/www/admin-next/pages/settings/company-types.vue?macro=true";
import { default as expense_45typesaf__qKHsDbTpf0jWoSZxZdSJ5eQkzwiuHbhXKX1gxegMeta } from "/var/www/admin-next/pages/settings/expense-types.vue?macro=true";
import { default as task_45prioritieszvyf5O4_OcWenDEoi6cCbpzEC0JLj9R1YsUX1OEIXXwMeta } from "/var/www/admin-next/pages/settings/task-priorities.vue?macro=true";
import { default as employment_45rolesRzKaEe_45dYTK_45md2nMM1YKJr5tfhWQ76UIrYY6TmWbI8Meta } from "/var/www/admin-next/pages/settings/employment-roles.vue?macro=true";
import { default as document_45categoriesxleGAzabbzBPe_45CPAns03ed1_45mZL8XfSqoSV0MRrexcMeta } from "/var/www/admin-next/pages/settings/document-categories.vue?macro=true";
import { default as research_45categoriesUQWhBbjTW_ysKsFGyc0HMaMqjssFW0OwWAmo0Eqna9MMeta } from "/var/www/admin-next/pages/settings/research-categories.vue?macro=true";
import { default as settingsjGVEZFGe8_45jv8TUohd0eCG8rWpH5sTfP7q6epMRPuREMeta } from "/var/www/admin-next/pages/settings.vue?macro=true";
import { default as analyticsgWqIj7y8DKllScqUNSeLV_45fnCGJKpTNsxOo_llXfMHAMeta } from "/var/www/admin-next/pages/analytics.vue?macro=true";
import { default as index9MJDJOvciswAk6VyjGEtxCUkPFp__sW5kgTkFU9es40Meta } from "/var/www/admin-next/pages/prospects/index.vue?macro=true";
import { default as importYJDFMO_xz2KghGwzT3DBs1VCc94ZzY6IH5EVN_nSQGQMeta } from "/var/www/admin-next/pages/prospects/import.vue?macro=true";
import { default as referralsViXpO_45_cbv1G7mtUDoMSolKsIQ74HXd9ZcDGpIjno4oMeta } from "/var/www/admin-next/pages/prospects/referrals.vue?macro=true";
import { default as _91id_93HJPc_NXkpefTVVtSrYCugq2SjYtAC9zzkY_cyihybDcMeta } from "/var/www/admin-next/pages/prospects/lists/[id].vue?macro=true";
import { default as master_45listds2sYg_452wgJBTnxT_XvTBcrEPyX2F1q0RK3FBSyDfNAMeta } from "/var/www/admin-next/pages/prospects/master-list.vue?macro=true";
import { default as outreach_45analyticstYBVviGvkpj4otWNhH_KwhGHyOUZ2QIUAq26bMiJweYMeta } from "/var/www/admin-next/pages/prospects/outreach-analytics.vue?macro=true";
import { default as prospectsiD2tht6siPUhFolz5AXkrCJks4G4sEvQE7oMiSll8oMMeta } from "/var/www/admin-next/pages/prospects.vue?macro=true";
import { default as _91id_93_hDqpNBSktbhQl4K6Hhyp1LE_45S7UIn4XZxaGfCG2jIQMeta } from "/var/www/admin-next/pages/tags/[id].vue?macro=true";
import { default as index2hYdmhtaxNsh1eVkoxkakH7DA4BEIcXmkjELOmTonZ8Meta } from "/var/www/admin-next/pages/compliance/index.vue?macro=true";
import { default as tasksOmfoooQTTJBo2oZiu_45nB7_kem2FhWWIMmvU484hXovYMeta } from "/var/www/admin-next/pages/compliance/tasks.vue?macro=true";
import { default as dnc_45listTBug1M_9VCR_45bSJhr_45Adu5cvrcZKlIMhViie_gXqAcUMeta } from "/var/www/admin-next/pages/compliance/dnc-list.vue?macro=true";
import { default as ppm_45logsNctZA_452xADI1A_MUdKS0gaYqexR7zfDz454GbMeRxj8Meta } from "/var/www/admin-next/pages/compliance/ppm-logs.vue?macro=true";
import { default as aml_45resourcesm7xDFMfuKvZbykzsJWcHqEeBo6ULvZzw_45f5NwFlRCuEMeta } from "/var/www/admin-next/pages/compliance/aml-resources.vue?macro=true";
import { default as complianceh3UXM5z5HI4XKMWOb7SIHZZecAvePWWiNMJjqOvhx_45UMeta } from "/var/www/admin-next/pages/compliance.vue?macro=true";
import { default as indexoDZjyhyDOLquOeCgFJK__45vVTMyoSgk1a2rUkhtTw2VQMeta } from "/var/www/admin-next/pages/tags/index.vue?macro=true";
import { default as _91id_93koClDMG72CSGhRS63dq23T19eHYV0ZoOltwoVsVxzQkMeta } from "/var/www/admin-next/pages/tasks/[id].vue?macro=true";
import { default as _91id_93IFpyVwGjEWBRxoSm30wvXrLsoz6j1T8sgYu_QIaq1KUMeta } from "/var/www/admin-next/pages/users/[id].vue?macro=true";
import { default as indexCcE8cLRNJVk2p4_45INFDtGVwT6c6d596NMQSxC3fb7QUMeta } from "/var/www/admin-next/pages/deals/index.vue?macro=true";
import { default as _91id_93JF5hwJOUPt0WAlHaCzJRoddYsnkWl_6NACY90NGVOV4Meta } from "/var/www/admin-next/pages/events/[id].vue?macro=true";
import { default as index5PV8nVbnTC2GpXBF6wNV2TZqWnkFm5QfuRxi9volBuAMeta } from "/var/www/admin-next/pages/tasks/index.vue?macro=true";
import { default as indexirIt5c5_2SzCU8rERp5sU5AuG5lSpy0_7FktDUAAisAMeta } from "/var/www/admin-next/pages/users/index.vue?macro=true";
import { default as _91id_93tjbti9TygN2GXS6XiizlnTln8EEu9WryXFnRQ0aTE_45kMeta } from "/var/www/admin-next/pages/projects/[id].vue?macro=true";
import { default as _91id_93hQbx3JMOyYiQo8UQmsZlVAFHeHrWPkIPLf_45rlfhXDEoMeta } from "/var/www/admin-next/pages/research/[id].vue?macro=true";
import { default as _91id_93m5k_45zl1Yeo1QaASk22mmThW9DbdonIoYDEw34hVtfcUMeta } from "/var/www/admin-next/pages/companies/[id].vue?macro=true";
import { default as _91id_93X7MmXxl6ETKYR5jIKw3nxY13URxylrO9VY40fK9Pn1EMeta } from "/var/www/admin-next/pages/documents/[id].vue?macro=true";
import { default as _91id_93FHzjxz1YR5ZKNWaAeZISdtdVXMAygJUexQWWh_455tgEkMeta } from "/var/www/admin-next/pages/investors/[id].vue?macro=true";
import { default as indexFwXp7AhkqhijEFJx3Syr3cd7C4Se3XseB1vhuNF1_ikMeta } from "/var/www/admin-next/pages/projects/index.vue?macro=true";
import { default as index33OC72HOddiRekOlIUgte2R7KidylCs3Oi9ddUEofusMeta } from "/var/www/admin-next/pages/research/index.vue?macro=true";
import { default as index3fOOsOeK0O1y9ZiYLQOLhfBnKWZQHh7vwN2JyB1ZivMMeta } from "/var/www/admin-next/pages/companies/index.vue?macro=true";
import { default as indexWbp_xtlCF6odgbPHH25dvixsi_45Y_7HbsYX5iwMWXvWAMeta } from "/var/www/admin-next/pages/documents/index.vue?macro=true";
import { default as indexivfi_45gEAEj7t7ej55_45J4Wrhp9lasXMm5f_45XeVlzqi10Meta } from "/var/www/admin-next/pages/deals/[id]/index.vue?macro=true";
import { default as _91id_93BuVIQi2Uau5V2IM0wEMyw7MuXGrzoWitzO_45uOJ1Nk6oMeta } from "/var/www/admin-next/pages/institutions/[id].vue?macro=true";
import { default as indexuuPYyCP7QQ_45JLg759Ba0J9KUrxvZOxY3NVokrBwVPyEMeta } from "/var/www/admin-next/pages/institutions/index.vue?macro=true";
import { default as pro_45rata_45allocationsv3S3oXG9Iwt4J9bKuC_N0njSfsBWduWzVhQ2HNe1l14Meta } from "/var/www/admin-next/pages/deals/[id]/pro-rata-allocations.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/admin-next/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logine_2WWutHG1k8VjQw__jBpD_T3UNrUq4vddq19kCh6B4Meta || {},
    component: () => import("/var/www/admin-next/pages/login.vue")
  },
  {
    name: merge2zXjvhycCD_457JqncaGpMbzGmHgPpbtAF8ywnvBOUt_458Meta?.name,
    path: "/merge",
    component: () => import("/var/www/admin-next/pages/merge.vue"),
    children: [
  {
    name: "merge-tags",
    path: "tags",
    component: () => import("/var/www/admin-next/pages/merge/tags.vue")
  },
  {
    name: "merge",
    path: "",
    component: () => import("/var/www/admin-next/pages/merge/index.vue")
  },
  {
    name: "merge-users",
    path: "users",
    component: () => import("/var/www/admin-next/pages/merge/users.vue")
  },
  {
    name: "merge-companies",
    path: "companies",
    component: () => import("/var/www/admin-next/pages/merge/companies.vue")
  },
  {
    name: "merge-institutions",
    path: "institutions",
    component: () => import("/var/www/admin-next/pages/merge/institutions.vue")
  }
]
  },
  {
    name: "amnesia",
    path: "/amnesia",
    component: () => import("/var/www/admin-next/pages/amnesia.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/admin-next/pages/profile.vue")
  },
  {
    name: "outreach",
    path: "/outreach",
    component: () => import("/var/www/admin-next/pages/outreach.vue")
  },
  {
    name: settingsjGVEZFGe8_45jv8TUohd0eCG8rWpH5sTfP7q6epMRPuREMeta?.name,
    path: "/settings",
    component: () => import("/var/www/admin-next/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/admin-next/pages/settings/index.vue")
  },
  {
    name: "settings-labels",
    path: "labels",
    component: () => import("/var/www/admin-next/pages/settings/labels.vue")
  },
  {
    name: "settings-licenses",
    path: "licenses",
    component: () => import("/var/www/admin-next/pages/settings/licenses.vue")
  },
  {
    name: "settings-deal-types",
    path: "deal-types",
    component: () => import("/var/www/admin-next/pages/settings/deal-types.vue")
  },
  {
    name: "settings-industries",
    path: "industries",
    component: () => import("/var/www/admin-next/pages/settings/industries.vue")
  },
  {
    name: "settings-task-types",
    path: "task-types",
    component: () => import("/var/www/admin-next/pages/settings/task-types.vue")
  },
  {
    name: "settings-user-types",
    path: "user-types",
    component: () => import("/var/www/admin-next/pages/settings/user-types.vue")
  },
  {
    name: "settings-deal-stages",
    path: "deal-stages",
    component: () => import("/var/www/admin-next/pages/settings/deal-stages.vue")
  },
  {
    name: "settings-event-types",
    path: "event-types",
    component: () => import("/var/www/admin-next/pages/settings/event-types.vue")
  },
  {
    name: "settings-account-types",
    path: "account-types",
    component: () => import("/var/www/admin-next/pages/settings/account-types.vue")
  },
  {
    name: "settings-company-types",
    path: "company-types",
    component: () => import("/var/www/admin-next/pages/settings/company-types.vue")
  },
  {
    name: "settings-expense-types",
    path: "expense-types",
    component: () => import("/var/www/admin-next/pages/settings/expense-types.vue")
  },
  {
    name: "settings-task-priorities",
    path: "task-priorities",
    component: () => import("/var/www/admin-next/pages/settings/task-priorities.vue")
  },
  {
    name: "settings-employment-roles",
    path: "employment-roles",
    component: () => import("/var/www/admin-next/pages/settings/employment-roles.vue")
  },
  {
    name: "settings-document-categories",
    path: "document-categories",
    component: () => import("/var/www/admin-next/pages/settings/document-categories.vue")
  },
  {
    name: "settings-research-categories",
    path: "research-categories",
    component: () => import("/var/www/admin-next/pages/settings/research-categories.vue")
  }
]
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/var/www/admin-next/pages/analytics.vue")
  },
  {
    name: prospectsiD2tht6siPUhFolz5AXkrCJks4G4sEvQE7oMiSll8oMMeta?.name,
    path: "/prospects",
    component: () => import("/var/www/admin-next/pages/prospects.vue"),
    children: [
  {
    name: "prospects",
    path: "",
    component: () => import("/var/www/admin-next/pages/prospects/index.vue")
  },
  {
    name: "prospects-import",
    path: "import",
    component: () => import("/var/www/admin-next/pages/prospects/import.vue")
  },
  {
    name: "prospects-referrals",
    path: "referrals",
    component: () => import("/var/www/admin-next/pages/prospects/referrals.vue")
  },
  {
    name: "prospects-lists-id",
    path: "lists/:id()",
    component: () => import("/var/www/admin-next/pages/prospects/lists/[id].vue")
  },
  {
    name: "prospects-master-list",
    path: "master-list",
    component: () => import("/var/www/admin-next/pages/prospects/master-list.vue")
  },
  {
    name: "prospects-outreach-analytics",
    path: "outreach-analytics",
    component: () => import("/var/www/admin-next/pages/prospects/outreach-analytics.vue")
  }
]
  },
  {
    name: "tags-id",
    path: "/tags/:id()",
    component: () => import("/var/www/admin-next/pages/tags/[id].vue")
  },
  {
    name: complianceh3UXM5z5HI4XKMWOb7SIHZZecAvePWWiNMJjqOvhx_45UMeta?.name,
    path: "/compliance",
    component: () => import("/var/www/admin-next/pages/compliance.vue"),
    children: [
  {
    name: "compliance",
    path: "",
    component: () => import("/var/www/admin-next/pages/compliance/index.vue")
  },
  {
    name: "compliance-tasks",
    path: "tasks",
    component: () => import("/var/www/admin-next/pages/compliance/tasks.vue")
  },
  {
    name: "compliance-dnc-list",
    path: "dnc-list",
    component: () => import("/var/www/admin-next/pages/compliance/dnc-list.vue")
  },
  {
    name: "compliance-ppm-logs",
    path: "ppm-logs",
    component: () => import("/var/www/admin-next/pages/compliance/ppm-logs.vue")
  },
  {
    name: "compliance-aml-resources",
    path: "aml-resources",
    component: () => import("/var/www/admin-next/pages/compliance/aml-resources.vue")
  }
]
  },
  {
    name: "tags",
    path: "/tags",
    component: () => import("/var/www/admin-next/pages/tags/index.vue")
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    component: () => import("/var/www/admin-next/pages/tasks/[id].vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/admin-next/pages/users/[id].vue")
  },
  {
    name: "deals",
    path: "/deals",
    component: () => import("/var/www/admin-next/pages/deals/index.vue")
  },
  {
    name: "events-id",
    path: "/events/:id()",
    component: () => import("/var/www/admin-next/pages/events/[id].vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/var/www/admin-next/pages/tasks/index.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/admin-next/pages/users/index.vue")
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    component: () => import("/var/www/admin-next/pages/projects/[id].vue")
  },
  {
    name: "research-id",
    path: "/research/:id()",
    component: () => import("/var/www/admin-next/pages/research/[id].vue")
  },
  {
    name: "companies-id",
    path: "/companies/:id()",
    component: () => import("/var/www/admin-next/pages/companies/[id].vue")
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    component: () => import("/var/www/admin-next/pages/documents/[id].vue")
  },
  {
    name: "investors-id",
    path: "/investors/:id()",
    component: () => import("/var/www/admin-next/pages/investors/[id].vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/var/www/admin-next/pages/projects/index.vue")
  },
  {
    name: "research",
    path: "/research",
    component: () => import("/var/www/admin-next/pages/research/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/var/www/admin-next/pages/companies/index.vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/var/www/admin-next/pages/documents/index.vue")
  },
  {
    name: "deals-id",
    path: "/deals/:id()",
    component: () => import("/var/www/admin-next/pages/deals/[id]/index.vue")
  },
  {
    name: "institutions-id",
    path: "/institutions/:id()",
    component: () => import("/var/www/admin-next/pages/institutions/[id].vue")
  },
  {
    name: "institutions",
    path: "/institutions",
    component: () => import("/var/www/admin-next/pages/institutions/index.vue")
  },
  {
    name: "deals-id-pro-rata-allocations",
    path: "/deals/:id()/pro-rata-allocations",
    component: () => import("/var/www/admin-next/pages/deals/[id]/pro-rata-allocations.vue")
  }
]