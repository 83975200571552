import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/var/www/admin-next/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/var/www/admin-next/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/var/www/admin-next/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/var/www/admin-next/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/var/www/admin-next/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/var/www/admin-next/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/var/www/admin-next/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/var/www/admin-next/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/var/www/admin-next/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/var/www/admin-next/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/var/www/admin-next/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/var/www/admin-next/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/var/www/admin-next/.nuxt/sentry-client-config.mjs";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/var/www/admin-next/.nuxt/pwa-icons-plugin.ts";
import plugin_qFoR2KwrfPbeYF1NbvQtNji8ub5JyA6jqzexZ5yyjV8 from "/var/www/admin-next/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/var/www/admin-next/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/var/www/admin-next/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/var/www/admin-next/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/var/www/admin-next/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/var/www/admin-next/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_iT8ELLbMNFiLNeAxBqCmeEcEDkpIt1C7ZqsUGUpbugo from "/var/www/admin-next/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_LwC6IsSsBex_IF7Qh_Tr0Ju_YI83g0Rn778LE_9f2C0 from "/var/www/admin-next/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_K7Nv7CLS9pqnKvPo4_D8Anr6utXepigxZ56Q7G93_4k from "/var/www/admin-next/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import chartjs_Xnj42LoH7TFZDhlJA1N_wePpXVF1oR8HmuGps2Rya2k from "/var/www/admin-next/plugins/chartjs.ts";
import echo_nfnQCajojU1EshRAYTuVVrBYUYjmZqE1Jp1VxyEUn7U from "/var/www/admin-next/plugins/echo.js";
import v_network_graph_KlnU4_02K9eQInTVoHxw5BdeOJMLcBGBw0mwVmrq66U from "/var/www/admin-next/plugins/v-network-graph.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/var/www/admin-next/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  plugin_qFoR2KwrfPbeYF1NbvQtNji8ub5JyA6jqzexZ5yyjV8,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  scrollbars_client_iT8ELLbMNFiLNeAxBqCmeEcEDkpIt1C7ZqsUGUpbugo,
  presets_LwC6IsSsBex_IF7Qh_Tr0Ju_YI83g0Rn778LE_9f2C0,
  variables_K7Nv7CLS9pqnKvPo4_D8Anr6utXepigxZ56Q7G93_4k,
  chartjs_Xnj42LoH7TFZDhlJA1N_wePpXVF1oR8HmuGps2Rya2k,
  echo_nfnQCajojU1EshRAYTuVVrBYUYjmZqE1Jp1VxyEUn7U,
  v_network_graph_KlnU4_02K9eQInTVoHxw5BdeOJMLcBGBw0mwVmrq66U,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ
]